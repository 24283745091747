<script setup>
import { computed } from 'vue';
import { useListingStore } from '~/stores/account/listings.store';

const listingsStore = useListingStore()
await listingsStore.fetchListingPreview()
const listing = computed(() => listingsStore.addedListingGetter())

const isShowMap = ref(false);

</script>
<template>
    <div class="flex flex-col item-center px-4 mt-2">
        <div class="images relative">
            <a class=" w-full flex flex-row flex-wrap gap-0">
                
            </a>
        </div>
        <div class="relative mb-4">
            <lightbox :images="listing.gallery_pictures" displayStyle="mobile" />
            <div 
                class="" 
                v-if="listing.features"
            >
                <div class="flex items-center opacity-60 text-black gap-5 text-base my-4">
                    <div 
                        class="flex flex-col items-center gap-1"
                        v-for="(feature, i) in listing.features.base_features"
                        :key="i"
                    >
                        <div class="h-10 w-10 leading-10 text-center bg-gray-300 rounded-full">
                            <i :class="`${feature.icon} text-gray-900`"></i>
                        </div>
                        <span>
                            {{ feature.name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="my-3 border-b">
                <h2 class="text-black text-2xl font-bold my-2">{{ listing.title }}</h2>
                <template v-if="listing?.location?.lat && listing?.location?.lng">
                    <button 
                        @click="() => isShowMap = !isShowMap"
                        type="button"
                        class="bg-indigo-200 text-indigo-900 rounded-lg my-3 p-2.5 border border-white"
                    >
                        <i class="fa-solid fa-location-dot"></i>
                        <span class="text-base ms-1">{{ $t('view_on_map') }}</span>
                    </button>
                    <shared-map-i-frame 
                        v-if="isShowMap" 
                        :lng="listing.location.lng" 
                        :lat="listing.location.lat"
                        :addMark="isShowMap"
                    />
                </template>
                <div v-html="listing.description"></div>
            </div>
            <div class="my-3 border-b">
                <h4 class="text-xl text-black mb-3">{{ $t('client.property_information') }}</h4>
                <div class="flex flex-col gap-4">
                    <div class="flex gap-3 border-b pb-3">
                        <span class="w-1/3">{{ $t('client.type') }}</span>
                        <span class="font-medium">{{ listing.category_name }}</span>
                    </div>
                    <div 
                        v-if="listing._id" 
                        class="flex gap-3 border-b pb-3"
                    >
                        <span class="w-1/3">{{ $t('client.ref_code') }}</span>
                        <span class="font-medium">#{{ listing.id }}</span>
                    </div>
                    <div 
                        class="flex gap-3 pb-3"
                    >
                        <span class="w-1/3">{{ $t('client.listing_date') }}</span>
                        <span class="font-medium">{{ new Date().toLocaleDateString() }}</span>
                    </div>
                </div>
            </div>
            <div v-if="listing.features" class="my-3 border-b">
                <h4 class="text-xl text-black mb-3">{{ $t('client.features') }}</h4>
                <template 
                    v-for="(feature, i) in listing.features.multi_features"
                    :key="i"
                >
                    <h5 class="text-base text-black mb-2 font-medium">{{ feature.name }}</h5>
                    <ul class="*:w-1/2 flex flex-wrap">
                        <li class="flex gap-2 mb-2 text-gray-600" v-for="sub_feature in feature.value">
                            <i class="fa-solid fa-check m-0.5"></i>
                            <span class="text-sm">{{ sub_feature.value }}</span>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </div>

    <div class="mb-12"></div>
    
    <div class="fixed bottom-0 bg-white p-4 w-full">
        <div class="flex items-center justify-between gap-3 w-full">
            <h1 class="text-xl font-bold text-indigo-900">{{ listing.symbol }} {{ listing.sale_price }}</h1>
            <div class="w-1/2 ms-auto flex items-center justify-end">
                <button 
                    type="button" 
                    @click="() => window.alert('dd')"
                    class="flex items-center px-3 justify-center text-center py-2.5 rounded-lg bg-indigo-900 text-white w-full"
                >
                    {{ $t('client.continue') }}
                </button>
            </div>
        </div>
    </div>
</template>